import React, { useEffect, useState } from "react";
import ProcurementIntakeStage from "./ProcurementIntakeStage";
import ProcurementSizingStage from "./ProcurementSizingStage";
import {
  Breadcrumb,
  Col,
  Descriptions,
  Divider,
  notification,
  Row,
  Space,
  Typography
} from "antd";
import ProcurementTimeline from "./ProcurementTimeline";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getProcurementDetails, updateProcurementStageItem } from "apis";
import { PROCUREMENT_STATUS, PROCUREMENT_TYPES } from "types";
const { Text } = Typography;
const SingleProcurementPage = () => {
  const [procurementDetails, setprocurementDetails] = useState([]);
  const [demandIntakeData, setDemandIntakeData] = useState({});
  const [procurementGeneralData, setProcurementGeneralData] = useState({});
  const [procurementSizingData, setProcurementSizingData] = useState({});
  const [isDataUpdated, setIsDataUpdated] = useState(false);
  const [isClusterSizeDisabled, setIsClusterSizeDisabled] = useState(true);
  const [resources, setResources] = useState({
    cpu_cores: 0,
    memory_: 0,
    storage_tb: 0,
  });

  const navigate = useNavigate();
  const handleBreadcrumbClick = (e, path) => {
    e?.preventDefault();
    navigate(path);
  };

  const [searchParams] = useSearchParams();
  const PK = searchParams.get("PK");
  const SK = searchParams.get("SK");
  useEffect(() => {
    getProcurementData();
  }, [isDataUpdated]);

  const getProcurementData = async () => {
    let result = await getProcurementDetails(PK);
    if (result.success) {
      setprocurementDetails(result.data);

      let demandIntakeDataObject = result.data.find(
        (item) => item.SK === "STAGE|Intake"
      );
      if (demandIntakeDataObject["grand_total"] === undefined) {
        let grand_total = {
          est_memory: 0,
          est_vcpus: 0,
          est_diskspace: 0,
          est_nics: 0,
        };
        grand_total["est_vcpus"] =
          parseInt(demandIntakeDataObject["subtotal"]["est_vcpus"], 10) +
          parseInt(demandIntakeDataObject["buffer"]["est_vcpus"], 10);
        grand_total["est_memory"] =
          parseInt(demandIntakeDataObject["subtotal"]["est_memory"], 10) +
          parseInt(demandIntakeDataObject["buffer"]["est_memory"], 10);
        grand_total["est_diskspace"] =
          parseInt(demandIntakeDataObject["subtotal"]["est_diskspace"], 10) +
          parseInt(demandIntakeDataObject["buffer"]["est_diskspace"], 10);
        grand_total["est_nics"] =
          parseInt(demandIntakeDataObject["subtotal"]["est_nics"], 10) +
          parseInt(demandIntakeDataObject["buffer"]["est_nics"], 10);
        demandIntakeDataObject = { ...demandIntakeDataObject, grand_total };
      }
      setDemandIntakeData(demandIntakeDataObject);
      setProcurementGeneralData(
        result.data.find((item) => item.PK === item.SK)
      );
      setProcurementSizingData(
        result.data.find((item) => item.SK === "STAGE|Sizing")
      );
    } else if (result.error) {
      notification.error({
        message:
          "Failed to retrieve Procurement info. Please try again or contact admin.",
      });
    }
  };

  const getTshirtSizeForClusterFromIntake = (
    tshirtSize,
    estimatedPrice,
    cpu,
    memory,
    storage
  ) => {
    setProcurementSizingData((prev) => ({
      ...prev,
      estimated_tshirt_size: tshirtSize,
      estimated_price: estimatedPrice,
      vcpu :cpu,
      memory: memory,
      storage: storage
    }));
    setResources((prev) => ({
      ...prev,
      cpu_cores: cpu,
      memory_: memory,
      storage_tb: storage,
    }));
  };

  const handlePageUpdate = () => {
    let currentvalue = isDataUpdated;
    if (currentvalue === false) {
      setIsDataUpdated(true);
    } else {
      setIsDataUpdated(false);
    }
  };

  const handleSizingUpdate = async (currentStageStatus, tshirtSize) => {
    let currentData = procurementSizingData;
    currentData = {
      ...currentData,
      estimated_tshirt_size:
        currentStageStatus === PROCUREMENT_STATUS.Submitted
          ? procurementSizingData["estimated_tshirt_size"]
          : tshirtSize,
      stage_status: currentStageStatus,
    };
    console.log("handleSizingUpdate", currentData);
    let res = await updateProcurementStageItem(currentData);
    if (res.success) {
      notification.success({
        message: "Sizing updated successful!!",
      });
      handlePageUpdate();
    } else {
      notification.error({
        message:
          "Failed to save sizing info. Please try again or contact admin.",
      });
    }
  };

  return (
    <>
      <Row justify={"center"} gutter={[10, 10]}>
        <Col span={18}>
          <Space>
            <Breadcrumb
              items={[
                {
                  title: "All Procurements",
                  onClick: (e) => handleBreadcrumbClick(e, "/"),
                  href: "/",
                },
                {
                  title: procurementGeneralData["procurement_name"],
                },
              ]}
            />
          </Space>
        </Col>
      </Row>
      <Row>
        <Col span={4} style={{ height: "60px" }} />
      </Row>
      <div style={{ padding: "0 60px" }}>
        <Row gutter={[60, 30]} justify={"center"}>
          {/* <Col span={7}>
            <ProcurementTimeline />
          </Col> */}

          <Col span={20}>
            <Row gutter={[0, 20]}>
              <Col span={24}>
                <div
                  id="overview-table-section"
                  style={{ border: "3px solid #f6f6f6", borderRadius: "10px" }}
                >
                  <Descriptions
                    bordered
                    size="small"
                    column={1}
                    layout="horizontal"
                  >
                    <Descriptions.Item label="Procurement Name">
                      {procurementGeneralData["procurement_name"]}
                    </Descriptions.Item>
                    <Descriptions.Item label="Cluster Name">
                      {demandIntakeData["cluster_name"]}
                    </Descriptions.Item>
                    <Descriptions.Item label="Procurement Type">
                      {procurementGeneralData["procurement_type"]}
                    </Descriptions.Item>
                  </Descriptions>
                </div>
              </Col>
              <Divider orientation="left">Demand Intake</Divider>
              <Col span={24}>
                <ProcurementIntakeStage
                  demandIntakeData={demandIntakeData}
                  isUpdate={true}
                  handlePageUpdate={handlePageUpdate}
                  procurementType={procurementGeneralData["procurement_type"]}
                  getTshirtSizeForClusterFromIntake={
                    getTshirtSizeForClusterFromIntake
                  }
                  setIsClusterSizeDisabled={setIsClusterSizeDisabled}
                  handleSizingUpdate={handleSizingUpdate}
                />
              </Col>
              <Divider orientation="left">Sizing</Divider>
              <Col span={24}>
                <ProcurementSizingStage
                  procurementSizingData={procurementSizingData}
                  // procurementType={PROCUREMENT_TYPES.NEW}
                  procurementType={procurementGeneralData["procurement_type"]}
                  isClusterSizeDisabled={isClusterSizeDisabled}
                  handlePageUpdate={handlePageUpdate}
                  resources={resources}
                />
              </Col>
              <Divider orientation="left">Bill of Material</Divider>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default SingleProcurementPage;
