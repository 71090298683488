import { Button, Collapse, Spin, notification } from "antd"
import React, { useState } from "react"
import ViewBuilder from "components/common/ViewBuilder/v2"
import { InfoCircleOutlined } from '@ant-design/icons'
import useMasterDataStore from "store/master_data_store"
import _ from "lodash"
import PrismData from "components/pages/SingleProcurementPage/PrismData"


const _allClusterNames = _.memoize((allClusterNames) => allClusterNames.map((item) => item.ClusterName))
const showExistingClusterInfo = (cluster_name) => {
    const allClusterNames = useMasterDataStore.getState().allClusterNames
    const cluster_name_options = _allClusterNames(allClusterNames)

    if (cluster_name_options.includes(cluster_name)) {
        window.open(window.location.origin + `/cluster/info/prism?cluster_name=${cluster_name}`)
    } else {
        notification.error({
            message: 'The given cluster name does not exist on the Prism Database.'
        })
    }
}
let empty_object = {}
const ClusterInfo = ({ info = empty_object, cluster_name = '' }) => {
    const [activeKey, setActiveKey] = useState("1"); 
    let nodes_data = info?.nodes ? [...info.nodes] : []
    if (info?.node_specs_totals) {
        nodes_data.push(info.node_specs_totals)
    }
    const handlePanelChange = (key) => { setActiveKey(activeKey === key ? null : key); }; 

    return (<Collapse activeKey={activeKey} onChange={handlePanelChange}  expandIconPosition="end">
        <Collapse.Panel
            key={1}
            // extra={<Button disabled={false} size="small" onClick={() => showExistingClusterInfo(cluster_name)}>View full Prism Data <InfoCircleOutlined /></Button>}
            header={<><h4 style={{margin:'0px'}}>  Details : {cluster_name}</h4> {info?.['all_values_same'] === false ? "(Not all nodes are the same)" : <></>}</>}
        >
            {!info?.nodes ?
                <>Fetching data from Prism Central, this can take a few seconds.. <Spin spinning /></>
                : 
                // <ViewBuilder
                //     view_data={nodes_data}
                //     table_library="ReactMaterialTable"
                //     enablePagination={false}
                //     enableTopToolbar={false}
                //     enableBottomToolbar={false} />
                <PrismData existingClusterInfo={info}/>
                    }
        </Collapse.Panel>
    </Collapse >)

}

export default React.memo(ClusterInfo)