import {
  Button,
  Col,
  Form,
  notification,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Typography,
} from "antd";
import { getAllTShirtSizes, updateProcurementStageItem } from "apis";
import React, { useEffect, useState } from "react";
import useMasterDataStore from "store/master_data_store";
import { PROCUREMENT_STATUS, PROCUREMENT_TYPES } from "types";
const { Text } = Typography;

function ProcurementSizingStage({
  procurementSizingData,
  procurementType,
  isClusterSizeDisabled,
  handlePageUpdate,
  resources,
}) {
  const [estimatedTshirtSize, setEstimatedTshirtSize] = useState("");
  const { allClusterNames, allClusterSizes } = useMasterDataStore();
  const [procrementSizingDatastate, setProcrementSizingDatastate] = useState(
    {}
  );
  const [clusterSizes, setClusterSizes] = useState([]);
  const [showSpin, setShowSpin] = useState(false);
  const [isSizingStageReadOnly, setIsSizingStageReadOnly] = useState(false);
  const [isApproveDisabled, setIsApproveDisabled] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  useEffect(() => {
    setEstimatedTshirtSize(procurementSizingData["estimated_tshirt_size"]);
    setProcrementSizingDatastate(procurementSizingData);
    getTshirtSizesData();
    if (
      procurementSizingData["stage_status"] === PROCUREMENT_STATUS.Approved ||
      procurementSizingData["stage_status"] === PROCUREMENT_STATUS.Initial
    ) {
      setIsSizingStageReadOnly(true);
    } else {
      setIsSizingStageReadOnly(false);
    }
  }, [procurementSizingData]);

  const getTshirtSizesData = async () => {
    if (procurementType) {
      let result = await getAllTShirtSizes(procurementType);
      console.log("TeshirtSizes", result.data);
      if (result.success) {
        setClusterSizes(result.data);
      } else {
        console.log(result.error);
      }
    }
  };

  const handleOnchangeDropdown = (value) => {
    setEstimatedTshirtSize(value);
  };

  const handleUpdateOnclick = () => {
    let currentData = procrementSizingDatastate;
    currentData = {
      ...currentData,
      estimated_tshirt_size: estimatedTshirtSize,
      stage_status: PROCUREMENT_STATUS.Submitted,
      resources: resources
    };
    setProcrementSizingDatastate(currentData);
    updateSizing(currentData);
    setIsApproveDisabled(false);
  };

  console.log("procurementSizingData",procurementSizingData)

  const updateSizing = async (currentData) => {
    setIsSizingStageReadOnly(false);
    let res = await updateProcurementStageItem(currentData);
    if (res.success) {
      notification.success({
        message: "Update successful!!",
      });
      setShowEdit(true);
    } else {
      notification.error({
        message:
          "Failed to save sizing info. Please try again or contact admin.",
      });
    }
  };

  const handleApproveButton = async () => {
    let currentData = procrementSizingDatastate;
    currentData = {
      ...currentData,
      estimated_tshirt_size: estimatedTshirtSize,
      stage_status: PROCUREMENT_STATUS.Approved,
      vcpu: resources?.cpu_cores,
      memory: resources?.memory_,
      storage: resources?.storage_tb,
    };

    let res = await updateProcurementStageItem(currentData);
    if (res.success) {
      setIsSizingStageReadOnly(true);
      notification.success({
        message: "Sizing approved",
      });
      setIsApproveDisabled(true);
      setShowEdit(false);
      handlePageUpdate();
    } else {
      notification.error({
        message: "Sizing approveal failed!",
      });
    }
  };

  const handleEditButton = () => {
    setIsSizingStageReadOnly(false);
    setShowEdit(true);
    setIsApproveDisabled(false);
  };

  const data = [
    {
      key: "resources",
      name: <Text strong>Resources</Text>,
      est_memory: (
        <Text>
          {resources?.memory_ !== 0
            ? resources?.memory_
            : procurementSizingData?.["resources"]?.['memory_']}
        </Text>
      ),
      est_vcpus: (
        <Text>
          {resources?.cpu_cores !== 0
            ? resources?.cpu_cores
            : procurementSizingData?.["resources"]?.['cpu_cores']}
        </Text>
      ),
      est_diskspace: (
        <Text>
          {resources?.storage_tb !== 0
            ? resources?.storage_tb
            : procurementSizingData?.["resources"]?.['storage_tb']}
        </Text>
      ),
    },
  ];
 

  const columns = [
    {
      title: "",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "No. of vCPUs",
      dataIndex: "est_vcpus",
      key: "est_vcpus",
    },
    {
      title: "RAM (in GB)",
      dataIndex: "est_memory",
      key: "est_memory",
    },
    {
      title: "Storage (in GB)",
      dataIndex: "est_diskspace",
      key: "est_diskspace",
    },
  ];

  return (
    <div
      id="initial-demand-section"
      style={{
        backgroundColor: "white",
        padding: "40px",
        border: "3px solid #f6f6f6",
        borderRadius: "10px",
      }}
    >
      <Form disabled={isSizingStageReadOnly}>
        <Row gutter={[20, 0]}>
          <Text>
            {procurementType === PROCUREMENT_TYPES.NEW
              ? "Estimated cluster size :"
              : "Additional Nodes :"}
          </Text>
          <Col span={16} style={{ marginBottom: "25px" }}>
            <Select
              // disabled={isClusterSizeDisabled}
              virtual={false}
              options={clusterSizes.map((item) => {
                return {
                  label: item["Tshirt_size"],
                  value: item["Tshirt_size"],
                };
              })}
              style={{ width: "70%" }}
              // value={estimatedTshirtSize?.replace("Gen-11", "").trim()}
              value={estimatedTshirtSize}
              onChange={handleOnchangeDropdown}
            />
          </Col>

          <Col span={24}>
            <Table
              dataSource={data}
              columns={columns}
              pagination={false}
              bordered
              size="small"
              style={{ marginBottom: "20px" }}
            />
          </Col>

          {showSpin && (
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(255, 255, 255, 0.8)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 1000,
              }}
            >
              <Spin size="large" tip="updating data..." />
            </div>
          )}
        </Row>
      </Form>
      <Space style={{ marginTop: "15px" }}>
        <Form.Item>
          {procurementSizingData["stage_status"] ===
            PROCUREMENT_STATUS.Approved && !showEdit ? (
            <Button type="primary" onClick={handleEditButton}>
              Edit
            </Button>
          ) : (
            <Button type="primary" onClick={handleUpdateOnclick}>
              Update
            </Button>
          )}
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            onClick={handleApproveButton}
            disabled={isApproveDisabled}
          >
            Approve
          </Button>
        </Form.Item>
      </Space>
    </div>
  );
}

export default ProcurementSizingStage;
